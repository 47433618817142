
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-leaderboard',

    props: {
      users: {
        default: () => [],
        type: Array,
      },
    }, // props
  });
